<template>
  <div>
    <v-dialog v-model="dialog" max-width="450px" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
          <v-icon dark>
            mdi-plus
          </v-icon>
          Create
        </v-btn>
      </template>
      <v-card tile>
        <v-card-title class="primary white--text">
          <span class="text-h5"><v-icon large color="white" class="mr-2">{{ formIcon }}</v-icon>{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row no-gutters>
              <v-col cols="12" class="my-2 d-flex justify-center" v-if="selectedImage">
                <v-img contain max-height="150" :src="selectedImage"></v-img>
              </v-col>
              <v-col cols="12">
                <v-file-input label="Logo" outlined dense @change="getImage" v-model="editedItem.image" prepend-icon=""
                  append-icon="mdi-image"></v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-alert v-if="error" dense type="error">
                  {{ error }}
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider class="primary" />
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="error" @click="close">
            Cancel
          </v-btn>
          <v-btn color="success" @click="save">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="error white--text"><v-icon color="white" class="mr-2">mdi-delete</v-icon> You sure you want
          to delete this item?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="closeDelete">Cancel</v-btn>
          <v-btn color="success" @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table :headers="headers" :items="data" sort-by="image" class="elevation-3">
      <template v-slot:item.actions="{ item }">
        <v-icon small @click="deleteItem(item)" class="error--text">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:item.image="{ item }">
        <v-avatar class="profile my-2" color="grey" size="160" tile>
          <v-img contain v-if="item.image" :src="item.image"></v-img>
        </v-avatar>
      </template>
      <template v-slot:no-data>
        <v-progress-circular v-if="loading" indeterminate color="secondary"></v-progress-circular>
        <v-icon v-else x-large color="grey lighten-1">
          mdi-tray-remove
        </v-icon>

      </template>
    </v-data-table>
  </div>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
export default {
  name: 'Reviews',
  mixins: [validationMixin],
  data: () => ({
    headers: [
      { text: 'Image', value: 'image' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    data: [],
    apiUrl: process.env.VUE_APP_API_URL,
    loading: true,
    dialog: false,
    dialogDelete: false,
    error: '',
    editedIndex: -1,
    editedItem: {
      image: ''
    },
    defaultItem: {
      image: ''
    },
    selectedImage: null,
  }),
  created() {
    this.getReviews();
  },
  methods: {
    editItem(item) {
      this.selectedImage = item.image
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.image = null
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.deleteReview(this.editedItem.id)
      this.closeDelete();
    },
    close() {
      this.selectedImage = null
      this.dialog = false;
      this.$v.$reset()
      this.error = ''
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.addReview()
    },
    getReviews() {
      this.loading = true
      this.$Axios
        .get('/api/v1/review/')
        .then((res) => {
          if (res.status === 200) {
            this.data = res.data;
            console.log(this.data)
          } else {
            this.error = res.data;
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.loading = false
        })
    },
    getImage() {
      let formData = new FormData();
      formData.append('img', this.editedItem.image);
      formData.append('source', "review");

      console.log(formData)

      this.$Axios({
        method: 'post',
        url: '/api/v1/util/upload-image',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(({ data }) => {
          console.log(data)
          this.selectedImage = data.img
        })
        .catch((e) => {
          console.log(e)
        });
    },
    addReview() {
      this.error = ''
      this.$v.$touch()
      console.log(this.$v)
      if (this.$v.$error) {
        return
      }
      let data = {}
      data.image = this.selectedImage
      this.$Axios.post('/api/v1/review/', data)
        .then(() => {
          this.getReviews();
          this.close();
        })
        .catch((e) => {
          console.log(e)
        });
    },
    deleteReview(id) {
      this.$Axios
        .delete('/api/v1/review/' + id)
        .then((res) => {
          if (res.status === 200) {
            this.getReviews();
          } else {
            this.error = res.data;
            alert(res.data)
          }
        })
        .catch((e) => {
          console.log(e);
        })
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Review' : 'Edit Review';
    },
    formIcon() {
      return this.editedIndex === -1 ? 'mdi-plus' : 'mdi-edit';
    },
    filtredItems() {
      if (this.filter) return this.desserts.filter(item => item.name.includes(this.filter) || item.license.includes(this.filter) || item.email.includes(this.filter) || item.db.includes(this.filter))
      else return this.desserts
    },
    imageErrors() {
      const errors = []
      if (!this.$v.editedItem.image.$dirty) return errors
      !this.$v.editedItem.image.required && errors.push('Image is required !')
      return errors
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  validations: {
    editedItem: {
      image: {
        required,
      },
    },
  },
};
</script>